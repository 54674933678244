import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w03MM01Schnuppern = () => (
  <Layout>
    <SEO title="w03MM01Schnuppern" />

    <h1 id="ueberschrift">Musik machen</h1>
    <h2>Schnuppertreff für Interessierte &emsp;  ©&nbsp;1993</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w03MM01Schnuppern&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        "Ohne Musik wär' alles nichts."<br />
        <div style={{fontSize: "0.75em"}}>Wolfgang Amadeus Mozart (1756 - 1791)</div>
      </p>

    <p>---</p>

      <p>
        Ich singe gerne unter der Dusche oder im Auto ...<br />
        ... doch wie steht es damit einfach einmal Musik mit anderen zu machen
        ... ohne dabei nachzudenken, wie gut oder schlecht andere das eigene Talent finden
        ... einfach mal jammen und dabei die Wirkung der Musik genießen...
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Sie können die Gelegenheit nutzen und einen ersten Eindruck gewinnen,
        indem Sie sich über die angegebene Kontaktmöglichkeit melden. Gemeinsam ermitteln wir dann den
        für Sie günstigsten Termin, damit Sie in das Themengebiet "hineinschnuppern" können.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Informationskurs (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Materialbedarf: + ggf. Instrument(e) +
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w03MM01Schnuppern
